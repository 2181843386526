/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports

import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { Button as AntdButton, ConfigProvider } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';

import adminAccounts from '../../../api/methods/adminAccounts';

import AdminAccountView from './components/AdminAccountView';
import AdminAccountForm from './components/AdminAccountForm';
import AdminAccountsList from './components/AdminAccountsList';
import buttonStyle from '../../../components/button/linearGradient';

export default function Settings() {
  const { styles } = buttonStyle();

  // Chakra Color Mode
  const [adminAccountsLoading, setAdminAccountsLoading] = useState(true);
  const [adminAccountsData, setAdminAccountsData] = useState([]);

  const [openAdminAccountForm, setOpenAdminAccountForm] = useState(false);
  const [openAdminAccountView, setOpenAdminAccountView] = useState(false);
  const [selectedAdminAccount, setSelectedAdminAccount] = useState(false);
  const [adminAccountFormType, setAdminAccountFormType] = useState('create');

  function fetchAdminAccounts() {
    setAdminAccountsLoading(true);
    adminAccounts
      .list()
      .then((response) => {
        console.log('Admin Accounts', response.data);
        setAdminAccountsData(response.data);
      })
      .finally(() => {
        setAdminAccountsLoading(false);
      });
  }
  useEffect(() => {
    fetchAdminAccounts();
  }, []);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <ConfigProvider
        button={{
          className: styles.linearGradientButton,
        }}
      >
        <AntdButton
          onClick={() => {
            setAdminAccountFormType('create');
            setOpenAdminAccountForm(true);
          }}
          type="primary"
          icon={<UserAddOutlined />}
          size={'large'}
          className="my-2 float-end w-48 text-lg font-medium"
        >
          Add User
        </AntdButton>
      </ConfigProvider>

      <AdminAccountsList
        adminAccountsData={adminAccountsData}
        fetchAdminAccounts={fetchAdminAccounts}
        setOpenAdminAccountForm={setOpenAdminAccountForm}
        setSelectedAdminAccount={setSelectedAdminAccount}
        setAdminAccountFormType={setAdminAccountFormType}
        adminAccountsLoading={adminAccountsLoading}
        setOpenAdminAccountView={setOpenAdminAccountView}
      />
      <AdminAccountForm
        openAdminAccountForm={openAdminAccountForm}
        setOpenAdminAccountForm={setOpenAdminAccountForm}
        fetchAdminAccounts={fetchAdminAccounts}
        selectedAdminAccount={selectedAdminAccount}
        adminAccountFormType={adminAccountFormType}
      />
      <AdminAccountView
        openAdminAccountView={openAdminAccountView}
        setOpenAdminAccountView={setOpenAdminAccountView}
        selectedAdminAccount={selectedAdminAccount}
      />
    </Box>
  );
}
