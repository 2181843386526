import React from 'react';
import {
  BentoGrid,
  BentoGridItem,
} from '../../../AceternityComponents/ui/bento-grid.tsx';
import images from '../data/images.jsx';

import {
  FaNetworkWired,
  FaRobot,
  FaTools,
  FaProjectDiagram,
  FaBrain,
  FaMicrochip,
  FaIndustry,
} from 'react-icons/fa';

export default function BentoGrid1() {
  return (
    <>
      <div className="w-full  mb-12">
        <BentoGrid className="max-w-6xl mx-auto my-auto md:auto-rows-auto">
          {items.map((item, i) => (
            <BentoGridItem
              key={i}
              title={item.title}
              description={item.description}
              header={item.header}
              icon={item.icon}
              className={
                i === 3 || i === 6 ? 'md:col-span-2 h-[20rem]' : 'h-[20rem]'
              }
            />
          ))}
        </BentoGrid>
      </div>
    </>
  );
}
const Skeleton = () => (
  <div className="flex flex-1 w-full h-full h-[12rem] rounded-xl bg-gradient-to-br from-neutral-200 dark:from-neutral-900 dark:to-neutral-800 to-neutral-100"></div>
);
const items = [
  {
    title: 'Internet of Things (IoT)',
    description: 'Building smart, connected devices for seamless automation.',
    header: (
      <img
        src={images.IoT}
        alt="IoT"
        className="h-[12rem] rounded-xl object-cover"
      />
    ),
    icon: <FaNetworkWired className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: 'Artificial Intelligence (AI)',
    description:
      'Developing intelligent solutions for data-driven decision making.',
    header: (
      <img
        src={images.AI}
        alt="AI"
        className="h-[12rem] rounded-xl object-cover"
      />
    ),
    icon: <FaBrain className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: 'Robotics',
    description: 'Designing advanced robotic systems for automated tasks.',
    header: (
      <img
        src={images.Robotics}
        alt="Robotics"
        className="h-[12rem] rounded-xl object-cover"
      />
    ),
    icon: <FaRobot className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: 'Fullstack SaaS Development',
    description: 'Creating end-to-end SaaS solutions tailored to your needs.',
    header: (
      <img
        src={images.FullStack}
        alt="FullStack"
        className="h-[12rem] rounded-xl object-cover"
      />
    ),
    icon: <FaProjectDiagram className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: 'Embedded Systems',
    description:
      'Integrating hardware and software for optimized device performance.',
    header: (
      <img
        src={images.Embedded}
        alt="Embedded"
        className="h-[12rem] rounded-xl object-cover"
      />
    ),
    icon: <FaMicrochip className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: 'Industry Automation',
    description:
      'Automating industrial processes to boost efficiency and productivity.',
    header: (
      <img
        src={images.IndustrialAutomation}
        alt="IndustrialAutomation"
        className="h-[12rem] rounded-xl object-cover"
      />
    ),
    icon: <FaIndustry className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: 'Hardware - PCB Designing & Product Development',
    description: 'Creating precise, innovative electronics solutions.',
    header: (
      <img
        src={images.Hardware}
        alt="Hardware"
        className="h-[12rem] rounded-xl object-cover"
      />
    ),
    icon: <FaTools className="h-4 w-4 text-neutral-500" />,
  },
];
