import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useToast, Button } from '@chakra-ui/react';
import { Label } from '../../src/AceternityComponents/ui/label.tsx';
import { Input } from '../../src/AceternityComponents/ui/input.tsx';
import { cn } from '../../lib/utils.ts';

import authApis from '../../api/methods/auth.jsx';

import {
  setPCRStatus,
  storeRefreshToken,
  storeToken,
  storeUserId,
  storeInstituteId,
} from '../../services/localServices.jsx';
import { token } from 'stylis';

export default function SignupForm() {
  const navigate = useNavigate();

  const toast = useToast();

  const [UIDdata, setUIDdata] = useState('');
  const [Tokendata, setTokdendata] = useState('');
  const [password, setPassword] = useState('');
  const [ConfirmPassword, setConfirmPassword] = useState('');

  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === '') {
      toast({
        title: `Please enter password`,
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (ConfirmPassword === '') {
      toast({
        title: `Please enter password`,
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (password !== ConfirmPassword) {
      toast({
        title: `Password and Confirm Password should be same`,
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setButtonLoading(true);
    authApis
      .changePassword({
        password: password,
        confirm_password: ConfirmPassword,
        uid: `${UIDdata}`,
        token: `${Tokendata}`,
      })
      .then((res) => {
        console.log(res);
        toast({
          title: 'Password Changed Successfully',
          description:
            'Your password has been updated. You can now sign in with your new credentials.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate('#SignIn');
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };
  const location = useLocation();
  useEffect(() => {
    const hash = location.hash ? location.hash.substring(1) : '';
    const hashQueryString = hash.includes('?') ? hash.split('?')[1] : '';
    const searchParams = new URLSearchParams(hashQueryString);
    let queryData1 = searchParams.get('uid');
    setUIDdata(queryData1);
    let queryData2 = searchParams.get('token');
    setTokdendata(queryData2);
    console.log(queryData1);
    console.log(queryData2);
    if (queryData1 === null && queryData2 === null) {
      navigate('#SignIn');
    }
  }, []);
  return (
    <div className="max-w-md w-full z-20 mx-auto rounded-none md:rounded-2xl p-4 md:p-8 shadow-input bg-white dark:bg-black">
      <h2 className="font-bold text-xl text-neutral-800 dark:text-neutral-200">
        Update Your Password
      </h2>
      <p className="text-neutral-600 text-sm max-w-sm mt-2 dark:text-neutral-300">
        Set a new password to keep your account safe.
      </p>

      <form className="my-8" onSubmit={handleSubmit}>
        <LabelInputContainer className="mb-4">
          <Label htmlFor="password">Password</Label>
          <Input
            required={true}
            id="password"
            value={password}
            placeholder="••••••••"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </LabelInputContainer>

        <LabelInputContainer className="mb-4">
          <Label htmlFor="password">Confirm Password</Label>
          <Input
            required={true}
            id="confirmPassword"
            value={ConfirmPassword}
            placeholder="••••••••"
            type="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </LabelInputContainer>
        <div className="h-10 flex justify-between items-center float-end cursor-pointer">
          <p
            className="text-neutral-800 text-[14px] max-w-sm mt-0 mb-2 font-medium  ease-in duration-300 dark:text-neutral-300"
            onClick={(e) => {
              navigate('#SignIn');
            }}
          >
            Return to Sign In ?
          </p>
        </div>
        <Button
          type="submit"
          // variant="unstyled" // Removes default styling
          width="full"
          height="10"
          fontWeight="medium"
          color="white"
          rounded="md"
          bg="gray.800"
          isLoading={buttonLoading}
          sx={{
            _hover: {
              backgroundColor: 'black',
            }, // Removes the hover effect
          }}
        >
          Change Password &rarr;
          <BottomGradient />
        </Button>

        {/* <div className="bg-gradient-to-r from-transparent via-neutral-300 dark:via-neutral-700 to-transparent my-8 h-[1px] w-full" /> */}
      </form>
    </div>
  );
}

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

const LabelInputContainer = ({ children, className }) => {
  return (
    <div className={cn('flex flex-col space-y-2 w-full', className)}>
      {children}
    </div>
  );
};
