import React from 'react';
import { motion } from 'framer-motion';
import AuroraBackground from '../../../AceternityComponents/ui/aurora-background.tsx';

import BentoGrid1 from '../Components/BentoGrid1.jsx';
export default function Section2() {
  return (
    <>
      <AuroraBackground style={{ height: '100%', width: '100%' }}>
        <motion.div
          initial={{ opacity: 0.0, y: 40 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{
            delay: 0.3,
            duration: 0.8,
            ease: 'easeInOut',
          }}
          className="relative flex flex-col gap-4 items-center justify-center px-4"
        >
          <div className="mt-8 text-2xl md:text-5xl font-bold dark:text-white text-center">
            Empowering Innovation Across Technology Domains
          </div>
          <div className="font-extralight text-base md:text-4xl text-center dark:text-neutral-200 py-1 md:py-4">
            Driving intelligent, connected, and automated solutions.
          </div>
          <BentoGrid1 />
        </motion.div>
      </AuroraBackground>
    </>
  );
}
