import axios from 'axios';
import {
  isLoggedIn,
  readRefreshToken,
  readToken,
  storeToken,
} from '../services/localServices';
import { API_URL } from '../services/DataConfig';

axios.defaults.baseURL = `${API_URL}`;

const NoAccessTokenURL = ['Admin/login'];
const noAlerts = [];

const HandleAlerts = (error, toast) => {
  if (!noAlerts.includes(window.location.pathname)) {
    if (error.response) {
      const status = error.response.status;
      if (status === 401) {
        // Handle unauthorized case if necessary
      } else if (status === 500) {
        toast({
          title: 'Oops! Something Went Wrong',
          description:
            'We’re experiencing some issues. Please try again shortly.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        const responseError = error.response.data;
        const errors = responseError.errors || responseError.error;

        if (errors) {
          const description =
            errors.email ||
            errors.non_field_errors?.[0] ||
            errors.non_field_error?.[0] ||
            errors;

          toast({
            title: 'Error',
            description,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });

          console.log('From Client.js Page --- ', description);
        }
      }
    } else {
      toast({
        title: 'An error occurred',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }
};

const handleSuccess = (response, toast) => {
  if (!noAlerts.includes(window.location.pathname)) {
    // Handle successful responses if necessary
  }
};

let isRefreshing = false;

const refreshAccessToken = async () => {
  isRefreshing = true;
  try {
    if (isLoggedIn) {
      const response = await axios.post('user/token/refresh/', {
        refresh: readRefreshToken(),
      });
      const newAccessToken = response.data.access;
      storeToken(newAccessToken);
      isRefreshing = false;
      return newAccessToken;
    } else {
      window.location.href = '/auth/sign-in';
    }
  } catch (error) {
    isRefreshing = false;
    console.error('Error refreshing access token:', error);
    throw error;
  }
};

axios.interceptors.request.use(
  (config) => {
    const accessToken = readToken();
    let AccessTokenNeeded = true;
    NoAccessTokenURL.map((data) => {
      if (config.url === data) {
        AccessTokenNeeded = false;
      }
    });

    if (accessToken && AccessTokenNeeded) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export const setupAxiosInterceptors = (toast) => {
  axios.interceptors.response.use(
    (response) => {
      handleSuccess(response, toast);
      return response;
    },
    async (error) => {
      HandleAlerts(error, toast);
      if (error.response) {
        const status = error.response.status;
        const originalRequest = error.config;

        if (status === 401 && !isRefreshing) {
          try {
            const newAccessToken = await refreshAccessToken();
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            return axios(originalRequest);
          } catch (refreshError) {
            console.error('Error refreshing access token:', refreshError);
          }
        }
      }

      return Promise.reject(error);
    },
  );
};

export default axios;
