const apiEndpoints = {
  auth: {
    login: 'Admin/login/',
    forgotPassword: 'forgot-password/',
    resetPassword: 'reset-password/',
  },
  adminAccounts: {
    list: 'Admin/get-admin-list/',
    create: 'Admin/create-admin/',
    update: 'Admin/edit-admin/',
    delete: 'Admin/delete-admin/',
  },
};

export default apiEndpoints;
