import React, { useState, useEffect } from 'react';
import { Modal, Descriptions } from 'antd';
import moment from 'moment';
export default function AdminAccountForm({
  openAdminAccountView,
  setOpenAdminAccountView,
  selectedAdminAccount,
}) {
  const [selectedAdminAccountData, setSelectedAdminAccountData] = useState([]);

  function FilterData(data) {
    return data ? data : '-';
  }

  useEffect(() => {
    let RowDetails = [
      {
        key: '1',
        label: 'First Name',
        children: FilterData(selectedAdminAccount?.first_name),
      },
      {
        key: '2',
        label: 'Last Name',
        children: FilterData(selectedAdminAccount?.last_name),
      },
      {
        key: '3',
        label: 'Username',
        children: FilterData(selectedAdminAccount?.username),
      },
      {
        key: '4',
        label: 'Email',
        children: FilterData(selectedAdminAccount?.email),
      },
      {
        key: '4',
        label: 'Designation',
        children: FilterData(selectedAdminAccount?.designation),
      },
      {
        key: '4',
        label: 'Created Date',
        children: FilterData(
          moment(selectedAdminAccount?.created_date).format(
            'MMMM Do YYYY, h:mm:ss a',
          ),
        ),
      },
    ];
    setSelectedAdminAccountData(RowDetails);
  }, [selectedAdminAccount]);
  return (
    <>
      <Modal
        // title={`Admin Account Data`}
        open={openAdminAccountView}
        footer={null}
        onCancel={() => {
          setOpenAdminAccountView(false);
        }}
        width={'600px'}
      >
        <Descriptions
          bordered={true}
          size="small"
          title="Admin Account Details"
          layout="vertical"
          items={selectedAdminAccountData}
        />
      </Modal>
    </>
  );
}
