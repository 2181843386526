import React, { useState } from 'react';
import { Space, Table, Tag } from 'antd';
import { Button, Popconfirm } from 'antd';
import adminAccounts from '../../../../api/methods/adminAccounts';

import moment from 'moment';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
const AdminAccountsList = ({
  adminAccountsData,
  fetchAdminAccounts,
  setOpenAdminAccountForm,
  setSelectedAdminAccount,
  setAdminAccountFormType,
  adminAccountsLoading,
  setOpenAdminAccountView,
}) => {
  const [deleteAccountLoading, setDeleteAccountLoading] = useState(false);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      render: (record, records) => (
        <span>{`${records?.first_name ? records?.first_name : 'First Name'} ${
          records?.last_name ? records?.last_name : 'Last Name'
        }`}</span>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 200,
      render: (record) => <span>{record ? record : '-'}</span>,
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      width: 150,
      render: (record) => <span>{record ? record : '-'}</span>,
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      key: 'designation',
      width: 150,
      render: (record) => <span>{record ? record : '-'}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (_, records) => (
        <Space size="middle">
          <Button
            color="default"
            icon={<EyeOutlined />}
            onClick={() => {
              setSelectedAdminAccount(records);
              setOpenAdminAccountView(true);
            }}
          />
          <Popconfirm
            title="Edit the Account"
            description="Are you sure to edit this Account?"
            onConfirm={() => {
              setSelectedAdminAccount(records);
              setAdminAccountFormType('edit');
              setOpenAdminAccountForm(true);
            }}
            onCancel={() => {
              console.log('cancel');
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<EditOutlined />} />
          </Popconfirm>
          <Popconfirm
            title="Delete the Account"
            description="Are you sure to delete this Account?"
            onConfirm={() => {
              setDeleteAccountLoading(true);
              adminAccounts
                .delete(records.id)
                .then((data) => {
                  fetchAdminAccounts();
                })
                .finally(() => {
                  setDeleteAccountLoading(false);
                });
            }}
            onCancel={() => {
              console.log('cancel');
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              icon={<DeleteOutlined />}
              loading={deleteAccountLoading}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        loading={adminAccountsLoading}
        dataSource={adminAccountsData}
        pagination={{
          pageSize: 15,
        }}
        scroll={{
          y: 600,
        }}
      />
    </>
  );
};
export default AdminAccountsList;
