import apiEndpoints from '../endpoints';
import apiClient from '../client';

const authApis = {
  login: (Payload) => apiClient.post(apiEndpoints.auth.login, Payload),
  forgotPassword: (Payload) =>
    apiClient.post(apiEndpoints.auth.forgotPassword, Payload),
  changePassword: (Payload) =>
    apiClient.post(apiEndpoints.auth.resetPassword, Payload),
};

export default authApis;
