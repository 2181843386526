import apiEndpoints from '../endpoints';
import apiClient from '../client';

const adminAccounts = {
  list: () => {
    return apiClient.get(`${apiEndpoints.adminAccounts.list}`);
  },
  create: (body) => {
    return apiClient.post(`${apiEndpoints.adminAccounts.create}`, body);
  },
  update: (id, body) => {
    return apiClient.post(`${apiEndpoints.adminAccounts.update}${id}/`, body);
  },
  delete: (id) => {
    return apiClient.delete(`${apiEndpoints.adminAccounts.delete}${id}/`);
  },
};

export default adminAccounts;
