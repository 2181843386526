import React from 'react';
import { motion } from 'framer-motion';
import AuroraBackground from '../../AceternityComponents/ui/aurora-background.tsx';
import Section1 from './Sections/Section1.jsx';
import Section2 from './Sections/Section2.jsx';

export default function Landing() {
  return (
    <>
      <Section1 />
      <Section2 />
    </>
  );
}
