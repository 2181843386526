import { theme } from 'antd';

function AntdConfig(isDarkMode) {
  const { defaultAlgorithm, darkAlgorithm } = theme;

  return {
    components: {
      Layout: {
        bodyBg: isDarkMode ? '#141414' : '#ffffff',
        headerBg: isDarkMode ? '#141414' : '#ffffff',
        siderBg: isDarkMode ? '#141414' : '#ffffff',
        triggerBg: isDarkMode ? '#141414' : '#ffffff',
        triggerColor: !isDarkMode ? '#141414' : '#ffffff',
      },
    },
    algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
  };
}

export default AntdConfig;
