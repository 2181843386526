import AI from '../Assets/img/AI.png';
import Embedded from '../Assets/img/Embedded.png';
import FullStack from '../Assets/img/FullStack.png';
import Hardware from '../Assets/img/Hardware.png';
import IndustrialAutomation from '../Assets/img/IndustrialAutomation.png';
import IoT from '../Assets/img/IoT.png';
import Robotics from '../Assets/img/Robotics.png';

export const images = {
  AI,
  Embedded,
  FullStack,
  Hardware,
  IndustrialAutomation,
  IoT,
  Robotics,
};

export default images;
